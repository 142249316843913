import type {
  MqttCredentials,
  AdminTypes,
} from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from './client';

import type {
  Basestation,
  Building,
  FallRiskLevel,
  Floor,
  Room,
} from '../../types';

// TODO: Finish breaking this out into seperate files in appropriate `data-access` folders

export const putBasestation = async (
  base: Omit<Basestation, 'audible'>,
): Promise<Basestation> => {
  try {
    const res = await Api.put(`/admin/v1/basestations/${base.id}`, base);

    return res && res.data;
  } catch (error) {
    console.error('error updating basestation');
    throw error;
  }
};

export const postBasestation = async (
  base: Omit<Basestation, 'audible'>,
): Promise<Basestation> => {
  try {
    const res = await Api.post('/admin/v1/basestations', base);

    return res && res.data;
  } catch (error) {
    console.error('error adding basestation');
    throw error;
  }
};

type OrganizationOnlyData = Pick<
  AdminTypes.Organization,
  'id' | 'displayName' | 'roles' | 'ipAllowList'
>;

export const postOrg = async (
  org: OrganizationOnlyData,
): Promise<AdminTypes.Organization> => {
  try {
    const res = await Api.post('/admin/v1/orgs', org);
    return res && res.data;
  } catch (error) {
    console.error('error adding organization');
    throw error;
  }
};

type NewRoom = {
  mainId: string;
  fallRiskLevel: FallRiskLevel;
  name: string;
  hide: boolean;
  deleted: boolean;
};

export const putRoom = async (room: NewRoom): Promise<Room> => {
  try {
    const { mainId, ...newRoom } = room;
    const res = await Api.put(`/admin/v1/rooms/${mainId}`, newRoom);
    return res && res.data;
  } catch (error) {
    console.error('error updating basestation');
    throw error;
  }
};

type EditRoom = {
  id: string;
  unit: string;
  fallRiskLevel: FallRiskLevel;
  name?: string;
  hide: boolean;
  deleted: boolean;
};

export const postRoom = async (room: EditRoom): Promise<Room> => {
  try {
    const res = await Api.post('/admin/v1/rooms', room);
    return res && res.data;
  } catch (error) {
    console.error('error adding basestation');
    throw error;
  }
};

export const putBuilding = async (building: Building): Promise<Building> => {
  try {
    const res = await Api.put(`/admin/v1/buildings/${building.id}`, building);

    return res && res.data;
  } catch (error) {
    console.error('error editing building');
    throw error;
  }
};

export const postBuilding = async (building: Building): Promise<Building> => {
  try {
    const res = await Api.post('/admin/v1/buildings', building);

    return res && res.data;
  } catch (error) {
    console.error('error adding building');
    throw error;
  }
};

export const getFloors = async () => {
  try {
    const res = await Api.get<Floor[]>('/admin/v1/floors');
    return res && res.data;
  } catch (error) {
    console.error('error getting floors');
    throw error;
  }
};

export const postFloor = async (floor: Floor) => {
  try {
    const res = await Api.post<Floor>('/admin/v1/floors', floor);
    return res && res.data;
  } catch (error) {
    console.error('error adding floor');
    throw error;
  }
};

export const getMqttCreds = async () => {
  try {
    const res = await Api.post<MqttCredentials>('/v1/users/mqtt', {});
    return res.data;
  } catch (error) {
    console.error('error getting mqtt creds');
    throw error;
  }
};
