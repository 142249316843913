import { skipToken, useQuery } from '@tanstack/react-query';

import { getOrg } from '../data-access/getOrg';

interface Params {
  orgId?: string;
  enabled?: boolean;
}

export const useAdminOrg = ({ orgId, enabled = true }: Params) =>
  useQuery({
    queryKey: ['org', orgId],
    queryFn: orgId && enabled ? async () => getOrg({ orgId }) : skipToken,
  });
