import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export const putOrg = async (org: AdminTypes.Organization) => {
  const res = await Api.put<AdminTypes.Organization>(
    `/admin/v1/orgs/${org.id}`,
    org,
  );

  return res.data;
};
