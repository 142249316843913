import { useMemo } from 'react';
import { Stack } from '@mui/material';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { categoriesWithFields, fields } from './EditOrganizationSettingsModal';

import { useIsAdmin } from '../../../../../hooks/useIsAdmin';
import { ShowPageField } from '../../../components/ShowPageField';
import { useTrainingVideos } from '../../../hooks/useTrainingVideo';
import FormCategory from '../../../modals/FormCategory';

import type { EditableSettings } from './EditOrganizationSettingsModal';
import type { Role } from '../../../../../../types';
import type { DataFieldOption } from '../../../types/DataFields';

interface Props {
  org?: AdminTypes.Organization;
  roles?: Role[];
  loading: boolean;
}

export const OrgSettingsPanel = ({ org, roles, loading }: Props) => {
  const { isAdmin } = useIsAdmin();

  const { data: trainingVideos, isLoading: trainingVideosLoading } =
    useTrainingVideos();

  const trainingVideoLabel = useMemo(
    () =>
      trainingVideos?.videos.find(
        (video) => video.url === org?.defaultTrainingVideoUrlMobile,
      )?.path,
    [org?.defaultTrainingVideoUrlMobile, trainingVideos?.videos],
  );

  const roleLabel = useMemo(
    () => roles?.find((role) => role.id === org?.defaultRoleId)?.displayName,
    [org?.defaultRoleId, roles],
  );

  const getFieldDisplayValue = (
    value: string | number,
    fieldName: keyof EditableSettings,
  ) => {
    // If the field is an array, join the values with a comma
    if (Array.isArray(org?.[fieldName])) {
      return (org?.[fieldName] as string[])?.join(', ');
    }

    // If the field is a boolean, return Yes or No
    if (fields[fieldName]?.editType === 'boolean') {
      return org?.[fieldName] ? 'Yes' : 'No';
    }

    // If the field is a select field, find the label of the selected option
    if (
      fields[fieldName]?.editType === 'select' &&
      typeof fields[fieldName]?.options?.[0] !== 'string'
    ) {
      return (
        (fields[fieldName]?.options as DataFieldOption[])?.find(
          (option) => option.value === org?.[fieldName],
        )?.label || ''
      );
    }

    // If the field is a notif expiration field, return 'Never' if the value is falsy
    if (fieldName.includes('ExpireAfter')) {
      return !org?.[fieldName] ? 'Never' : String(value);
    }

    if (fieldName === 'defaultRoleId') {
      return roleLabel || '';
    }

    if (fieldName === 'defaultTrainingVideoUrlMobile') {
      return trainingVideoLabel || '';
    }

    return String(value);
  };

  const visibleCategories = isAdmin
    ? Object.keys(categoriesWithFields)
    : ['General'];

  return (
    <Stack gap={2} my={2}>
      {visibleCategories.map((category) => (
        <FormCategory
          defaultExpanded={
            category === 'General' || category === 'Notifications'
          }
          isAccordion
          key={category}
          title={category}
        >
          {categoriesWithFields[category].map((field) => {
            const baseValue = (
              org?.[field] || org?.[field] === 0 ? org[field] : ''
            ) as string;

            const value = getFieldDisplayValue(baseValue, field);

            return (
              <ShowPageField
                key={field}
                label={fields[field]?.label}
                value={value}
                loading={
                  loading ||
                  (field === 'defaultTrainingVideoUrlMobile' &&
                    trainingVideosLoading)
                }
                tooltip={fields[field]?.tooltip}
                data-testid={`org-${field}`}
              />
            );
          })}
        </FormCategory>
      ))}
    </Stack>
  );
};
