import { create } from 'zustand';

import type { WasabiImage } from '@inspiren-monorepo/shared-react/universal';
import type { EventReviewTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import type { Unit } from '../../../../types';

type ErrorInfo = string | { message: string; code: number } | null;

interface State {
  selectedOrg: EventReviewTypes.Organization | null;
  selectedUnit: Unit | null;
  selectedRoom: EventReviewTypes.Room | null;
  startDate: Date | null;
  endDate: Date | null;
  position: number;
  images: WasabiImage[] | null;
  lastImagesLoading: boolean;
  imagesLoading: boolean;
  imagesError: ErrorInfo;
  showStaffEventMarks: boolean;
  showNotifMarks: boolean;
}

interface Actions {
  setSelectedOrg: (newValue: EventReviewTypes.Organization | null) => void;
  setSelectedUnit: (newValue: Unit | null) => void;
  setSelectedRoom: (newValue: EventReviewTypes.Room | null) => void;
  setStartDate: (newValue: Date | null) => void;
  setEndDate: (newValue: Date | null) => void;
  incrementPosition: () => void;
  setPosition: (newValue: number) => void;
  setImages: (newValue: WasabiImage[] | null) => void;
  setImagesLoading: (newValue: boolean) => void;
  setImagesError: (newValue: ErrorInfo) => void;
  setShowStaffEventMarks: (newValue: boolean) => void;
  setShowNotifMarks: (newValue: boolean) => void;
  setLastImagesLoading: (newValue: boolean) => void;
  reset: () => void;
}

const initialState: State = {
  selectedOrg: null,
  selectedUnit: null,
  selectedRoom: null,
  startDate: null,
  endDate: null,
  position: 0,
  images: null,
  imagesLoading: false,
  imagesError: null,
  showStaffEventMarks: true,
  showNotifMarks: true,
  lastImagesLoading: false,
};

export const useEventReviewStore = create<State & Actions>((set) => ({
  ...initialState,
  // TODO: Remove the types in function arguments, they're already set in the interface
  setSelectedOrg: (newValue: EventReviewTypes.Organization | null) =>
    set({ selectedOrg: newValue }),
  setSelectedUnit: (newValue: Unit | null) => set({ selectedUnit: newValue }),
  setSelectedRoom: (newValue: EventReviewTypes.Room | null) =>
    set({ selectedRoom: newValue }),
  setStartDate: (newValue: Date | null) => set({ startDate: newValue }),
  setEndDate: (newValue: Date | null) => set({ endDate: newValue }),
  incrementPosition: () => set((state) => ({ position: state.position + 1 })),
  setPosition: (newValue: number) => set({ position: newValue }),
  setImages: (newValue: WasabiImage[] | null) => set({ images: newValue }),
  setImagesLoading: (newValue: boolean) => set({ imagesLoading: newValue }),
  setImagesError: (newValue: ErrorInfo) => set({ imagesError: newValue }),
  setShowStaffEventMarks: (newValue: boolean) =>
    set({ showStaffEventMarks: newValue }),
  setShowNotifMarks: (newValue: boolean) => set({ showNotifMarks: newValue }),
  setLastImagesLoading: (newValue: boolean) =>
    set({ lastImagesLoading: newValue }),
  reset: () => set(initialState),
}));
