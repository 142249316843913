import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export const getOrg = async ({
  orgId,
}: {
  orgId: AdminTypes.Organization['id'];
}) => {
  const res = await Api.get<AdminTypes.Organization>(`/admin/v1/orgs/${orgId}`);
  return res.data;
};
