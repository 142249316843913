import classifyPt from 'robust-point-in-polygon';

import type { Point, RegionConfig } from '@inspiren-monorepo/shared-types';

import { colors } from './colors';

export const convertToAbsolute = (pt: Point, width: number, height: number) => {
  if ((!pt.x && pt.x !== 0) || (!pt.y && pt.y !== 0)) return pt;
  return {
    x: Math.max(Math.min(pt.x, 1), 0) * width,
    y: Math.max(Math.min(pt.y, 1), 0) * height,
  };
};

export const getRegionColor = (region: string) => {
  switch (region) {
    case 'bed':
      return colors.regionBed;
    case 'chair':
      return colors.regionChair;
    case 'bedEdge':
      return colors.regionBedEdge;
    case 'ignore':
      return colors.regionIgnore;
    case 'exit':
      return colors.regionExit;
    default:
      return colors.regionChair;
  }
};

export const snapToEdge = (xY: number, length: number) =>
  xY < 10 ? 0 : xY > length - 10 ? length : xY;

export const drawRegion = (
  ctx: CanvasRenderingContext2D,
  points: Point[],
  region: string,
  isHighlighted = false,
) => {
  const color = getRegionColor(region);
  ctx.beginPath();
  ctx.strokeStyle = color.stroke;
  ctx.lineWidth = isHighlighted ? 5 : 3;
  ctx.lineJoin = 'round';

  points.forEach((point) => {
    ctx.lineTo(point.x, point.y);
  });

  ctx.closePath();

  ctx.stroke();
  ctx.fillStyle = color.fill;
  ctx.fill('evenodd');

  // draw point circles separately so they don't make the lines wonky
  points.forEach((point) => {
    ctx.beginPath();
    ctx.arc(point.x, point.y, 5, Math.PI * 2, 0, false);
    ctx.stroke();
  });
};

// this is a helper function to check if a point is within 15 pixels of another point
export const getPointWithin15Pixels = (
  allPoints: Point[],
  x: number,
  y: number,
) => {
  const pointWithin15Pixels = allPoints.find((point) => {
    const xDiff = Math.abs(point.x - x);
    const yDiff = Math.abs(point.y - y);
    return xDiff < 15 && yDiff < 15;
  });

  return pointWithin15Pixels;
};

const dragPriority = {
  bed: 0,
  chair: 1,
  bedEdge: 2,
  exit: 3,
  ignore: 4,
};

// this is a helper function
// it maps the region points to be in the same format expected for the classifyPt function
// the classifyPoint function will take array and mouse x,y positon and determine if this point is within the polygon
export const getDragRegionId = (
  allRegions: RegionConfig[],
  x: number,
  y: number,
) => {
  const { selectedRegionId } = allRegions.reduce(
    (draggedRegionObj, region) => {
      const { points, type, id } = region;
      const firstPoint = points[0];

      if (firstPoint) {
        const classify = classifyPt(
          points.map((point) => [point.x, point.y]),
          [x, y],
        );

        if (classify === -1) {
          const { selectedRegionPriority } = draggedRegionObj;
          const regionPriority = dragPriority[type];

          if (regionPriority < selectedRegionPriority) {
            return {
              selectedRegionId: id || '',
              selectedRegionPriority: regionPriority,
            };
          }
        }
      }

      return draggedRegionObj;
    },
    { selectedRegionId: '', selectedRegionPriority: 10000 },
  );

  return selectedRegionId;
};

interface LineSegment {
  start: Point;
  end: Point;
}

const linesIntersect = (
  { start: aStart, end: aEnd }: LineSegment,
  { start: bStart, end: bEnd }: LineSegment,
) => {
  const { x: a, y: b } = aStart;
  const { x: c, y: d } = aEnd;
  const { x: p, y: q } = bStart;
  const { x: r, y: s } = bEnd;

  const det = (c - a) * (s - q) - (r - p) * (d - b);

  if (det === 0) {
    return false;
  }

  const lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
  const gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
  return lambda > 0 && lambda < 1 && gamma > 0 && gamma < 1;
};

export const distanceToPoint = (point1: Point, point2: Point) =>
  Math.sqrt((point1.x - point2.x) ** 2 + (point1.y - point2.y) ** 2);

function calculateDistanceToLineSegment(
  newPoint: Point,
  { start, end }: LineSegment,
) {
  const { x, y } = newPoint;
  const { x: xStart, y: yStart } = start;
  const { x: xEnd, y: yEnd } = end;
  const A = x - xStart;
  const B = y - yStart;
  const C = xEnd - xStart;
  const D = yEnd - yStart;

  const dot = A * C + B * D;
  const lenSq = C * C + D * D;
  let param = lenSq !== 0 ? dot / lenSq : -1;

  if (param < 0) {
    param = 0;
  } else if (param > 1) {
    param = 1;
  }

  const xx = xStart + param * C;
  const yy = yStart + param * D;

  return distanceToPoint(newPoint, { x: xx, y: yy });
}

// need to check if ignore region is inverted
// can't just check if points are in exact corners in case it is off by a few pixels
export const checkIfInverted = (points: Point[], w: number, h: number) => {
  const lastFourPoints = points.slice(-4);
  const topLeft = lastFourPoints.some((point) => point.x < 10 && point.y < 10);

  const topRight = lastFourPoints.some(
    (point) => point.x < 10 && point.y > h - 10,
  );

  const bottomLeft = lastFourPoints.some(
    (point) => point.x > w - 10 && point.y < 10,
  );

  const bottomRight = lastFourPoints.some(
    (point) => point.x > w - 10 && point.y > h - 10,
  );

  return topLeft && topRight && bottomLeft && bottomRight && points.length > 4;
};

/*
  Inversion is done by connecting the region points to the four corners
  The connection is done from the first point of the region
  To attempt to safeguard against a sliver of ignore region connecting through
  the center, we are checking which corner is closest to the first point
  and setting the order of the corners based on that
*/
export const getCornerArrayForInversion = (
  connectionPoint: Point,
  w: number,
  h: number,
) => {
  const cornerPoints = [
    { x: 0, y: 0 },
    { x: 0, y: h },
    { x: w, y: h },
    { x: w, y: 0 },
  ];

  const closestCornerIndex = cornerPoints.reduce(
    (closeObj, corner, cornerIndex) => {
      const distance = distanceToPoint(connectionPoint, corner);

      if (closeObj.distance > distance) {
        return {
          closestIndex: cornerIndex,
          distance,
        };
      }

      return closeObj;
    },
    { closestIndex: 0, distance: 100000000 },
  );

  for (let i = 0; i < closestCornerIndex.closestIndex; i += 1) {
    cornerPoints.push(cornerPoints.shift() as Point);
  }

  cornerPoints.push(cornerPoints[0]);
  return cornerPoints;
};

export const addPointToRegion = (
  points: Point[],
  newPoint: Point,
  w: number,
  h: number,
) => {
  if (points.length < 3) {
    return [...points, newPoint];
  }

  let insertIndex = 0;
  let closestDistance = 100000000;

  const invertedRegion = checkIfInverted(points, w, h);

  const pointsToAddTo = invertedRegion ? points.slice(0, -6) : points;

  for (let i = 0; i < pointsToAddTo.length; i += 1) {
    const nextPointIndex = (i + 1) % pointsToAddTo.length;
    const nextPoint = pointsToAddTo[nextPointIndex];

    const point = pointsToAddTo[i];

    const currentLineSegment = {
      start: point,
      end: nextPoint,
    };

    const pointDistance = calculateDistanceToLineSegment(
      newPoint,
      currentLineSegment,
    );

    if (pointDistance <= closestDistance) {
      const intersectsWithExistingLine = pointsToAddTo.find(
        (lineStart, lineStartIndex) =>
          (lineStartIndex !== i &&
            linesIntersect(currentLineSegment, {
              start: lineStart,
              end: pointsToAddTo[(lineStartIndex + 1) % pointsToAddTo.length],
            })) ||
          linesIntersect(
            { start: newPoint, end: nextPoint },
            {
              start: lineStart,
              end: pointsToAddTo[(lineStartIndex + 1) % pointsToAddTo.length],
            },
          ),
      );

      if (!intersectsWithExistingLine) {
        closestDistance = pointDistance;
        insertIndex = i + 1;
      }
    }
  }

  return [
    ...points.slice(0, insertIndex),
    newPoint,
    ...points.slice(insertIndex),
  ];
};

export const isOverTheEdge = (
  point: Point,
  moveX: number,
  moveY: number,
  width: number,
  height: number,
) => {
  const pastX = point.x + moveX > width || point.x + moveX < 0;
  const pastY = point.y + moveY > height || point.y + moveY < 0;
  return pastX || pastY;
};

export const movePoint = (point: Point, moveX: number, moveY: number) => ({
  ...point,
  x: point.x + moveX,
  y: point.y + moveY,
});

// this helper function will add the distance of the mouse moved to the current set points
export const addDistanceTravelled = (
  points: Point[],
  x: number,
  y: number,
  width: number,
  height: number,
) => {
  const overTheEdge = points.some((point) =>
    isOverTheEdge(point, x, y, width, height),
  );

  return overTheEdge ? points : points.map((point) => movePoint(point, x, y));
};

// this helper function will take all the region points and divide them by the width and height of the canvas
export const convertToRelative = (
  points: Point[],
  width: number,
  height: number,
) =>
  points.map((point) => ({
    x: point.x / width,
    y: point.y / height,
  }));
